import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
      <g id="F" transform="translate(0.000000,101.000000) scale(0.100000,-0.100000)" fill="#6AFFE1" stroke="none">
        <path d="M877 994 c-173 -26 -370 -82 -512 -144 -65 -28 -215 -112 -215 -120
        0 -4 24 -7 52 -9 29 -1 54 -4 57 -6 2 -3 -32 -82 -77 -177 -44 -95 -92 -207
        -107 -248 -30 -86 -77 -271 -70 -279 3 -2 6 -2 8 0 137 198 389 409 621 518
        56 26 112 53 126 60 20 9 -1 10 -109 6 -73 -3 -164 -10 -202 -16 -38 -6 -74
        -9 -79 -7 -20 6 155 170 245 230 50 34 153 92 230 130 76 38 141 71 144 73 6
        7 -11 5 -112 -11z"/>
      </g>

  </svg>
);

export default IconLoader;
